/* Configura requireJS e inicia o aplicativo na página */
(function main() {
    "use strict";

    require(["angular", "./mainModule", "./config/applicationServices"], function mainRequire(angular, app) {
        angular.element(document).ready(function () {
            angular.bootstrap(document, [app.name], {
                strictDi: true
            });
            angular.element("html")
                .attr("ng-app", app.name)
                .attr("ng-strict-di", true);
        });
    });
}());
